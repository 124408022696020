import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import CommonParagraph from "../component/Blog/CommonParagraph";
import Heading from "../component/Blog/Heading";
import BlogLink from "../component/Blog/BlogLink";
import { blogList } from "../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/mira-mesa-real-estate-market-your-ultimate-homebuyers-guide"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By {blogData[0].author}
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_Homebuying in San Diego_ Everything You Need to Know About Closing Costs.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                When it comes to the most desirable San Diego neighborhoods, communities like La
                Jolla, Coronado, and Del Mar often steal the show.
              </CommonParagraph>
              <CommonParagraph>
                This is good news for homebuyers looking for a more affordable, under-the-radar
                community where they can raise a family—without giving up San Diego’s culture,
                beaches, and nightlife.
              </CommonParagraph>
              <CommonParagraph>
                These are only a few reasons to give the Mira Mesa real estate market a second look.
                Let’s explore why more and more families are choosing this community over all the
                others.
              </CommonParagraph>
              <Heading type="h2">Award-Winning Schools</Heading>
              <CommonParagraph>
                Mira Mesa’s population is only 80,000, yet it has 16 schools, including two colleges
                and universities—a high number relative to the community’s size. Many of these
                institutions are award-winning; six are California Distinguished School Award
                recipients. As the awards suggest, the district also scores high in graduation
                rates, college readiness, and post-secondary success.
              </CommonParagraph>
              <Heading type="h2">Family-Friendly Activities</Heading>
              <CommonParagraph>
                Living in Mira Mesa also makes you close to numerous kid-friendly attractions,
                including the Ned Baumer Aquatic Center—a hidden gem many locals still don't know
                about. It's a joint-use facility with Miramar Community College and consists of
                three pools, including a children's pool with beach-front entry. And because Mira
                Mesa is centrally located, it's relatively easy to get to Aquatica San Diego (Chula
                Vista), Legoland Water Park (Carlsbad), The Wave Waterpark (Vista), and many others.
              </CommonParagraph>
              <CommonParagraph>
                The Mira Mesa Recreation Center also facilitates a variety of sports leagues and
                clinics, art, math, and science classes, open gym times, multi-purpose fields,
                outdoor basketball courts, and more.
              </CommonParagraph>
              <CommonParagraph>
                Mira Mesa Lanes is another local favorite, as are the nearby Sky Zone Trampoline
                Park and the ice arena in the University Town Center.
              </CommonParagraph>
              <Heading type="h2">More Urban Villages; Less Strip Malls</Heading>
              <CommonParagraph>
                Mira Mesa is still up-and-coming, which means now is the perfect time to get in on
                the ground floor. It won’t remain a best-kept secret for long, especially when you
                learn more about San Diego’s development plans.
              </CommonParagraph>
              <CommonParagraph>
                In 2022, the city initiated plans to ease congestion in Mira Mesa by incentivizing
                developers to break up car-centric superblocks with 91 miles of bike lanes,
                footbridges over busy roadways, and even aerial skyways.
              </CommonParagraph>
              <CommonParagraph>
                Mixed-use areas are also in the works, and ambitious plans include opening more than
                100 acres of parkland with 17 miles of trails, two new recreation centers, and a new
                fire station and aquatic complex.
              </CommonParagraph>
              <CommonParagraph>
                This is only the beginning. Needless to say, we are pleased with the direction Mira
                Mesa is seemingly headed.
              </CommonParagraph>
              <Heading type="h2">Close Proximity to Nature</Heading>
              <CommonParagraph>
                <b>Black Mountain Open Space Park</b> offers over 2,000 acres and 10 miles of
                multi-use regional trails that wind through chaparral and sage-covered hills,
                ridges, and canyons. A sunset with breathtaking 360-degree views from the top of
                Black Mountain is a must-see.
              </CommonParagraph>
              <CommonParagraph>
                <b>Los Peñasquitos Canyon Preserve</b> is another local favorite. It is known for
                its cascading waterfall, year-round stream, Native American history (some adobe
                structures still stand), and vibrant plants and wildlife. More than 500 species of
                plants and 175 types of birds call the canyon home.
              </CommonParagraph>
              <CommonParagraph>
                <b>Mission Trails Regional Park</b> is roughly 20 minutes from Mira Mesa and offers
                over 7,000 acres with more than 60 miles of hiking and biking trails, a rock
                climbing area, a visitor center, and a campground.{" "}
              </CommonParagraph>
              <CommonParagraph>
                Tecolote Canyon Natural Park and Sycamore Canyon Open Space Preserve are also within
                a 25-minute drive of Mira Mesa.{" "}
              </CommonParagraph>
              <Heading type="h2">Let Us Help You Find the Perfect Family-Friendly Home</Heading>
              <CommonParagraph>
                <BlogLink
                  url="https://selbysellssd.com/san-diego-real-estate-agents/"
                  text="The Selby Team"
                />{" "}
                is all about family, and we’re dedicated to moving yours forward— whether it’s to
                family-friendly Mira Mesa or any of the other{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/"
                  text="surrounding communities"
                />{" "}
                we live in and love. No matter your preferences—a family-oriented suburb, the
                excitement of the big city, or a mix of both—we'll work tirelessly to find the
                perfect fit. <ContactSlideoutLink text="Contact us now!" />
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
